@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&family=Roboto:wght@400;500&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(207, 65%, 65%);
  --title-color: hsl(207, 4%, 16%);
  --text-color: hsl(207, 4%, 28%);
  --text-color-light: hsl(207, 4%, 56%);
  --body-color: hsl(207, 4%, 99%);
  --container-color: hsl(207, 4%, 95%);

  /* Change the first number */
  /* Blue: 207 - Purple: 250 - Pink: 356 - Teal: 174 */
  --gradient-color: linear-gradient(
    180deg,
    hsla(174, 48%, 72%, 0),
    hsla(174, 65%, 65%, 1)
  );

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Roboto", sans-serif;
  --title-font: "Lora", serif;
  --biggest-font-size: 1.5rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  --tiny-font-size: 0.625rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;

  --arrow-color-light: #99484d;
  --arrow-color-dark: #ffffff;
}
@media (prefers-color-scheme: dark) {
  :root {
    --arrow-color: var(--arrow-color-dark);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --arrow-color: var(--arrow-color-light);
  }
}
/* Responsive typography */
@media screen and (min-width: 1024px) {
  :root {
    --biggest-font-size: 3rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
    --tiny-font-size: 0.688rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  font-family: "Cairo", sans-serif;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  transition: background 0.4s; /*for dark mode animation */
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: var(--font-medium);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== THEME ===============*/
.nav__buttons {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.change-theme {
  font-size: 1.25rem;
  color: var(--title-color);
  cursor: pointer;
  transition: color 0.3s;
}

/*========== Variables Dark theme ==========*/

body.dark-theme {
  --title-color: hsl(207, 4%, 95%);
  --text-color: hsl(207, 4%, 65%);
  --body-color: hsl(207, 4%, 10%);
  --container-color: hsl(207, 4%, 12%);
}

/*========== 
    Color changes in some parts of 
    the website, in light theme
==========*/
.dark-theme .nav,
.dark-theme .nav__menu,
.dark-theme .scrollup {
  background-color: var(--container-color);
  box-shadow: 0 4px 20px hsl(207, 24%, 8%, 0.4);
}

.dark-theme .home__shape-wawes,
.dark-theme .home__shape-circle,
.dark-theme .qualification__img,
.dark-theme .testimonial__img {
  filter: invert(1);
}

.dark-theme .home__social-link {
  color: var(--title-color);
}

.dark-theme .section__border {
  border-bottom: 1px solid hsl(207, 4%, 32%);
}

.dark-theme::-webkit-scrollbar {
  background-color: hsl(207, 4%, 15%);
}

.dark-theme::-webkit-scrollbar-thumb {
  background-color: hsl(207, 4%, 25%);
}

.dark-theme::-webkit-scrollbar-thumb:hover {
  background-color: hsl(207, 4%, 35%);
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 1500px;
  /* margin-inline: 1.5rem; */
}

.grid {
  display: grid;
  align-items: center;
  justify-content: space-between;
  gap: 5.5rem;
}
.home__title {
  margin-bottom: 20px;
}
.home__content {
  margin: 0 auto;
  max-width: 650px;
}
.home__description {
  direction: rtl;
  text-align: right;
  font-size: 1.093rem;
}

.section {
  padding-block: 2.5rem;
}

.section__border {
  border-bottom: 1px solid var(--title-color);
  padding-bottom: 3.5rem;
}

.section__title,
.section__subtitle {
  text-align: center;
}

.section__title {
  font-size: var(--h1-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 0.25rem;
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  color: var(--text-color-light);
  margin-bottom: 3rem;
}

.main {
  overflow: hidden; /* For animation */
}

/*=============== HEADER & NAV ===============*/
.header {
  width: 100%;
  position: fixed;
  bottom: 2rem;
  left: 0;
  z-index: var(--z-fixed);
}
.nav {
  height: calc(var(--header-height) + 0.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--body-color);
  box-shadow: 0 4px 20px hsla(207, 24%, 35%, 0.1);
  padding-inline: 1.5rem;
  border-radius: 3rem;
  transition: background 0.4s; /*for dark mode animation */
}

.nav__logo,
.nav__toggle,
.nav__close {
  color: var(--title-color);
}

.nav__logo {
  font-family: var(--title-font);
  font-weight: var(--font-medium);
}

.nav__toggle {
  display: flex;
  font-size: 1.25rem;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .nav__menu {
    position: fixed;
    width: 88%;
    left: 0;
    right: 0;
    bottom: -60%;
    margin: 0 auto;
    background-color: var(--body-color);
    box-shadow: 0 4px 20px hsla(207, 24%, 35%, 0.1);
    padding: 2rem 1.5rem 5rem;
    border-radius: 2rem;
    transition: bottom 0.3s;
  }
}

.nav__list {
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  gap: 2rem 3rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
  /* font-size: var(--smaller-font-size); */
  color: var(--text-color-light);
  transition: color 0.3s;
}

.nav__link:hover {
  color: #99484d;
}

.nav__list i {
  font-size: 1.8rem;
}
.nav__list a {
  font-size: 1.2rem;
}

.nav__close {
  position: absolute;
  right: 1.5rem;
  bottom: 0.7rem;
  font-size: 1.5rem;
  cursor: pointer;
}
/* Show menu */
.show-menu {
  bottom: 2rem;
}

/* Active link */
.active-link {
  color: #99484d;
}

/*=============== HOME ===============*/
.home__container {
  row-gap: 4rem;
  padding-top: 2rem;
}

.home__data {
  row-gap: 1.5rem;
}

.home--title {
  text-align: center;
  font-size: var(--biggest-font-size);
  letter-spacing: 0.3px;
  margin-bottom: 0.5rem;
}

.home__blob {
  position: relative;
  width: 200px;
  height: 290px;
  background-color: var(--body-color);
  border: 2px solid var(--text-color-light);
  justify-self: center;
  border-radius: 6.25rem;
  place-items: center;
  transition: background 0.4s; /*for dark mode animation */
}

.home__perfil {
  width: 170px;
  height: 260px;
  background: var(--gradient-color);
  border-radius: 3.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.home__perfil img {
  width: 150px;
}

.home__shape-wawes,
.home__shape-circle {
  position: absolute;
  opacity: 0.1;
}
.home__shape-wawes {
  width: 50px;
  left: -1.5rem;
  top: 5rem;
}
.home__shape-circle {
  width: 150px;
  bottom: -2rem;
  right: -3rem;
  transform: rotate(15deg);
  z-index: -1;
}

.home__social {
  justify-self: center;
  display: flex;
  column-gap: 1.25rem;
}

.home__social-link {
  font-size: 1.25rem;
  color: var(--text-color-light);
  transition: color 0.3s;
}
.home__social-link:hover {
  color: #99484d !important;
}

.home__info {
  display: grid;
  row-gap: 2rem;
}

.home__info-title {
  font-family: var(--body-font);
  font-size: var(--smaller-font-size);
  font-weight: 400;
  color: var(--text-color-light);
  margin-bottom: 1rem;
}

.home__info-description,
.home__info-number {
  font-family: var(--title-font);
  color: var(--title-color);
}

.home__info-description {
  padding-right: 4rem;
}
.home__info-number {
  font-size: var(--h1-font-size);
  font-weight: var(--font-semi-bold);
}
/*=============== SKILLS ===============*/
.skills__container {
  row-gap: 3.5rem;
}

.skills__title {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 2.5rem;
}

.skills__title i {
  font-size: 1rem;
  font-weight: initial;
}

.skills__info,
.skills__data,
.skills__blod {
  display: grid;
}

.skills__info {
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem 2rem;
}

.skills__data {
  justify-content: center;
  text-align: center;
}

.skills__blod {
  width: 80px;
  height: 100px;
  background-color: var(--container-color);
  border-radius: 3rem;
  place-items: center;
  margin-bottom: 1rem;
  transition: background 0.4s; /*for dark mode animation */
}

.skills__blod img {
  width: 40px;
}
.skills__name {
  font-size: var(--small-font-size);
  margin-bottom: 0.25rem;
}

.skills__subtitle {
  font-size: var(--tiny-font-size);
  color: var(--text-color-light);
}
/* Animation skills */
.skills__blod:hover img {
  animation: bounce-skills 0.6s;
}
@keyframes bounce-skills {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(-6px);
  }
  40% {
    transform: translate(0);
  }
  60% {
    transform: translateY(-3px);
  }
  80% {
    transform: translate(0);
  }
}
/*=============== QUALIFICATION ===============*/

.qualification {
  position: relative;
}
.qualification__container {
  row-gap: 3.5rem;
}
.qualification__title {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 2rem;
}

.qualification__title i {
  font-size: 1rem;
  font-weight: initial;
}

.qualification__info {
  display: grid;
  row-gap: 2rem;
}

.qualification__name {
  font-size: var(--normal-font-size);
  margin-bottom: 1.25rem;
}

.qualification__country,
.qualification__year {
  display: block;
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.qualification__country {
  margin-bottom: 0.75rem;
}

.qualification__img {
  width: 150px;
  position: absolute;
  right: -3rem;
  bottom: 12rem;
  transform: rotate(15deg);
  opacity: 0.1;
}
/*=============== SERVICES ===============*/

.services__container {
  row-gap: 2.5rem;
  justify-content: center;
}

.services__card {
  position: relative;
  /* width: 650px !important;
  height: 322px; */
  background-color: var(--container-color);
  text-align: center;
  /* padding: 1.5rem 1rem; */
  border-radius: 0.25rem;
  transition: background 0.4s; /*for dark mode animation */
  margin: 0 auto;
}
@media (max-width: 1150px) {
  .services__card {
    width: 350px !important;
    height: 422px;
  }
}

.services__card i {
  display: block;
  font-size: 2rem;
  color: var(--title-color);
  /* margin-bottom: 0.75rem; */
  transition: transform 0.8s;
}

.services__title {
  font-size: var(--h1-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 1.5rem;
}
.services__description {
  font-size: 1.1rem;
}

.services__card:hover i {
  transform: rotateY(360deg);
}
/* 
.services__card:hover .services__border {
  opacity: 1;
} */
/*=============== PROJECTS ===============*/

.projects__container {
  overflow: initial;
}
.projects__content {
  display: grid;
  justify-content: center;
}

.projects__img {
  width: 250px;
  border-radius: 0.75rem;
  margin-bottom: 1.25rem;
}

.projects__subtitle {
  font-size: var(--h2-font-size);
  color: var(--text-color-light);
}
.projects__title {
  font-size: var(--small-font-size);
  margin-bottom: 1.25rem;
  margin: 0.2rem 0 1.25rem;
  max-width: 300px;
  direction: rtl;
}
.projects__button {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  color: var(--title-color);
  font-size: var(--small-font-size);
}

.projects__button i {
  font-size: 1rem;
  transition: transform 0.3s;
}

.projects__button:hover i {
  transform: translateX(0.25rem);
}
/* Swiper class */
.projects__container .swiper-button-prev::after,
.projects__container .swiper-button-next::after {
  content: "";
}
.projects__container .swiper-button-prev,
.projects__container .swiper-button-next {
  width: initial;
  height: initial;
  margin: initial;
  font-size: 2.5rem;
  color: var(--title-color);
}

.projects__container .swiper-button-prev {
  left: -1rem;
  top: 4.5rem;
}

.projects__container .swiper-button-next {
  right: -1rem;
  /* ////////////////////////////////// */
  top: 4.5rem;
}

.projects__container .swiper-slide {
  margin-bottom: 4.5rem;
}
.projects__container .swiper-pagination-bullets {
  bottom: 0;
}

.projects__container .swiper-pagination-bullet {
  background-color: var(--text-color-light);
  opacity: initial;
}

.projects__container .swiper-pagination-bullet-active {
  background-color: var(--title-color);
}

/*=============== TESTIMONIAL ===============*/
.testimonial {
  position: relative;
}

.testimonial__content {
  text-align: center;
}
.testimonial__description {
  font-family: var(--title-font);
  color: var(--title-color);
  margin-bottom: 1.5rem;
}
.testimonial__name {
  font-family: var(--body-font);
  font-size: var(--small-font-size);
  margin-bottom: 0.25rem;
}

.testimonial__subtitle {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
}

.testimonial__img {
  width: 70px;
  transform: rotate(30deg);
  position: absolute;
  top: 5rem;
  right: -1.5rem;
  opacity: 0.1;
}
/* Swiper class */
.testimonial__container .swiper-slide {
  margin-bottom: 4rem;
}

.testimonial__container .swiper-button-prev::after,
.testimonial__container .swiper-button-next::after {
  content: "";
}

.testimonial__container .swiper-button-prev,
.testimonial__container .swiper-button-next {
  font-size: 2rem;
  color: var(--title-color);
  width: initial;
  height: initial;
  top: initial;
  bottom: 0.5rem;
}

.testimonial__container .swiper-button-prev {
  left: calc(50% - 3rem);
}

.testimonial__container .swiper-button-next {
  right: calc(50% - 3rem);
}

.testimonial__container .swiper-button-disabled {
  opacity: initial;
  color: var(--text-color-light);
}

/*=============== CONTACT ===============*/
.contact__container {
  row-gap: 3.5rem;
}
.contact__title {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 2.5rem;
}
.contact__title i {
  font-size: 1rem;
  font-weight: initial;
}

.contact__info,
.contact__data,
.contact__form {
  display: grid;
}

.contact__info {
  row-gap: 2rem;
}
.contact__data {
  row-gap: 0.75rem;
}
.contact__data-title {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.contact__data-info {
  font-size: var(--small-font-size);
  font-family: var(--title-font);
  color: var(--title-color);
}

.contact__button {
  width: max-content;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  color: var(--title-color);
  font-size: var(--small-font-size);
}

.contact__button i {
  font-size: 1rem;
  transition: transform 0.3s;
}

.contact__button:hover i {
  transform: translateX(0.25rem);
}

.contact__form {
  position: relative;
  row-gap: 2rem;
}

.contact__form-div {
  position: relative;
  height: 4rem;
}

.contact__form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid var(--text-color-light);
  border-radius: 2rem;
  padding: 1.5rem;
  font-size: var(--normal-font-size);
  font-family: var(--title-font);
  color: var(--title-color);
  background: none;
  outline: none;
  z-index: 1;
}

.contact__form-tag {
  position: absolute;
  top: -0.75rem;
  left: 1.25rem;
  z-index: 10;
  background-color: var(--body-color);
  color: var(--title-color);
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  padding: 0.35rem;
  transition: background 0.4s; /*for dark mode animation */
}

.contact__form-area {
  height: 10rem;
  margin-bottom: 1rem;
}

.contact__form-area textarea {
  resize: none;
}

.contact__form .contact__button {
  border: none;
  background: none;
  font-size: var(--h2-font-size);
  font-family: var(--title-font);
  font-weight: var(--font-semi-bold);
  cursor: pointer;
  outline: none;
  margin-top: 0.75rem;
}

.contact__form .contact__button i {
  font-size: 1rem;
  font-weight: initial;
}

.contact__message {
  font-size: var(--small-font-size);
  position: absolute;
  bottom: 2.8rem;
  left: 1.5rem;
}
/* Status color */
.color-red {
  color: hsl(4, 71%, 50%);
}

.color-blue {
  color: hsl(207, 56%, 45%);
}

/*=============== FOOTER ===============*/
.footer__container {
  padding: 3rem 0 7rem;
  text-align: center;
}
.footer__title {
  font-size: var(--h1-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: 1rem;
  color: #99484d;
}

.footer__list,
.footer__social {
  display: flex;
  justify-content: center;
}

.footer__list {
  margin: 2.5rem;
  column-gap: 2.5rem;
}

.footer__link {
  color: var(--title-color);
}

.footer__link:hover {
  text-decoration: underline;
}

.footer__social {
  column-gap: 1.25rem;
  padding-left: 0;
}

.footer__social-link {
  font-size: 2.25rem;
  color: var(--title-color);
  transition: transform 0.3s;
}
.footer__social-link:hover {
  transform: translateY(-0.15rem);
}

.footer__copy {
  display: block;

  font-size: var(--normal-font-size);
  color: var(--text-color-light);
}
/*=============== SCROLL BAR ===============*/

::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.5rem;
  background-color: hsl(207, 4%, 75%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(207, 4%, 65%);
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: hsl(207, 4%, 55%);
}
/*=============== SCROLL UP ===============*/
.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -30%;
  background-color: var(--body-color);
  box-shadow: 0 4px 12px hsl(207, 24%, 35%, 0.15);
  display: inline-flex;
  color: var(--title-color);
  padding: 0.35rem;
  border-radius: 50%;
  font-size: 1.1rem;
  z-index: var(--z-tooltip);
  transition: bottom 0.3s, transform 0.3s;
}

.scrollup:hover {
  transform: translateY(-0.25rem);
}
/* Show Scroll Up*/
.show-scroll {
  bottom: 7.5rem;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */

@media screen and (max-width: 340px) {
  /* .container{
    margin-inline: 1rem;
  } */

  .nav__menu {
    padding-bottom: 4rem;
  }
  .nav__list {
    gap: 1rem 1.25rem;
  }
  .skills__info {
    grid-template-columns: repeat(2, 1fr);
  }
  .projects__img {
    width: 200px;
    justify-self: center;
  }
}

/* For medium devices */

@media screen and (min-width: 576px) {
  .nav,
  .nav__menu {
    width: 380px;
  }
  .nav {
    margin: 0 auto;
  }
  .skills__container {
    justify-content: center;
  }
  .projects__container,
  .testimonial__container {
    width: 400px;
  }
  .projects__container {
    overflow: hidden;
  }
  .projects__container .swiper-button-prev {
    left: 1rem;
  }
  .projects__container .swiper-button-next {
    right: 1rem;
  }
}

@media screen and (min-width: 767px) {
  .home__container {
    grid-template-columns: 1.5fr 2fr;
  }
  .home__data {
    order: 2;
  }
  .home__info:nth-child(3) {
    order: 3;
    text-align: right;
  }
  .home__info {
    margin-top: 10rem;
  }
  .home__info-description {
    padding-right: 0;
  }
  .skills__container,
  .qualification__container,
  .services__container,
  .contact__container {
    grid-template-columns: repeat(2, max-content);
  }
  .skills__container,
  .services__container {
    column-gap: 5rem;
  }
  .qualification__container {
    justify-content: center;
    column-gap: 10rem;
  }

  .projects__container {
    width: 500px;
  }
  .contact__form {
    width: 360px;
  }
  .contact__container {
    justify-content: center;
    column-gap: 8rem;
  }
}
/* For large devices */

@media screen and (min-width: 1023px) {
  .header {
    top: 0;
    bottom: initial;
    background-color: var(--body-color);
    transition: 0.4s;
  }
  .nav {
    width: initial;
    height: calc(var(--header-height) + 1.5rem);
    box-shadow: none;
    border-radius: 0;
    column-gap: 3rem;
    /* margin-inline: 1.5rem; */
    padding: 0;
  }
  .nav__link i,
  .nav__toggle,
  .nav__close {
    display: none;
  }
  .nav__menu {
    width: initial;
    margin-left: auto;
    background-color: var(--body-color);
    transition: background 0.4s;
  }
  .nav__list {
    display: flex;
    column-gap: 3rem;
  }
  .nav__link {
    font-size: var(--normal-font-size);
  }
  .dark-theme .nav,
  .dark-theme .nav__menu {
    background-color: var(--body-color);
    box-shadow: none;
  }
  .change-theme {
    color: var(--text-color-light);
  }
  .change-theme:hover {
    color: var(--title-color);
  }

  .section {
    padding-block: 8rem 0;
  }
  .home--title {
    font-size: 2.25rem;
  }
  .scrollup {
    right: 3rem;
  }
  .show-scroll {
    bottom: 5rem;
  }

  change background header .bg-header {
    box-shadow: 0 4px 20px hsl(207, 24%, 35%, 0.1);
  }

  .dark-theme .bg-header {
    box-shadow: 0 6px 24px hsl(207, 24%, 8%, 0.6);
  }
}

@media screen and(min-width: 1048px) {
  .container {
    margin-inline: auto;
  }
}

@media screen and (min-width: 1200px) {
  .section__border {
    padding-bottom: 6rem;
  }
  .section__subtitle {
    margin-bottom: 2.5rem;
    font-size: 1.1rem;
  }

  .home__title {
    font-size: var(--biggest-font-size);
  }

  .home__blob {
    width: 400px;
    height: 580px;
    border-radius: 12.5rem;
  }

  .home__perfil {
    width: 340px;
    height: 520px;
    border-radius: 10.6rem;
  }

  .home__perfil img {
    width: 300px;
  }

  .home__shape-wawes {
    width: 100px;
    left: -4rem;
    top: 10rem;
  }
  .home__shape-circle {
    width: 250px;
    bottom: -1rem;
    right: -5rem;
  }
  .home__data {
    row-gap: 2.5rem;
  }
  .home__social {
    column-gap: 2rem;
  }
  .home__social-link {
    font-size: 1.5rem;
  }
  .home__info {
    grid-template-rows: repeat(3, 130px);
    row-gap: 4rem;
    margin-top: 16rem;
  }

  .skills__container {
    column-gap: 10rem;
  }

  .skills__title {
    margin-bottom: 3rem;
  }

  .skills__title i {
    font-size: 1.25rem;
  }
  .skills__name {
    font-size: var(--normal-font-size);
  }
  .skills__info {
    gap: 3rem;
  }
  .qualification__container {
    column-gap: 14rem;
  }

  .qualification__title {
    margin-bottom: 3rem;
  }
  .qualification__title i {
    font-size: 1.25rem;
  }
  .qualification__name {
    font-size: var(--h3-font-size);
  }
  .qualification__img {
    width: 300px;
    left: -6rem;
    bottom: 5rem;
  }
  .services__container {
    grid-template-columns: repeat(2, max-content);
  }
  .services__card {
    width: 245px;
    height: 345px;
    border-radius: 0.6rem;
    padding: 1.5rem 1.5rem;
  }
  .services__card i {
    font-size: 2.5rem;
  }

  /* .services__border {
    width: 265px;
    height: 365px;
    border-radius: 8.25rem;
  } */

  .projects__container {
    width: 900px;
  }

  .projects__container .swiper-button-prev,
  .projects__container .swiper-button-next {
    font-size: 3rem;
  }

  .projects__container .swiper-button-prev {
    left: -1rem;
  }

  .projects__container .swiper-button-next {
    right: -1rem;
  }

  .projects__container .swiper-slide {
    margin-bottom: 6.5rem;
  }

  .projects__img {
    width: 320px;
    margin-bottom: 2rem;
  }

  .testimonial__container {
    width: 566px;
  }
  .testimonial__container .swiper-button-prev,
  .testimonial__container .swiper-button-next {
    font-size: 2.5rem;
  }
  .testimonial__container .swiper-button-prev {
    left: calc(50% - 5rem);
  }
  .testimonial__container .swiper-button-next {
    right: calc(50% - 5rem);
  }

  .testimonial__container .swiper-slide {
    margin-bottom: 6rem;
  }

  .testimonial__description {
    font-size: var(--h2-font-size);
    margin-bottom: 2rem;
  }
  .testimonial__img {
    width: 200px;
    top: 13rem;
    right: 4rem;
  }
  .contact__title {
    margin-bottom: 3rem;
  }

  .contact__title i {
    font-size: 1.25rem;
  }
  .contact__info {
    row-gap: 3rem;
  }
  .contact__data-info {
    font-size: var(--normal-font-size);
  }
  .contact__form {
    row-gap: 2.5rem;
  }
  .footer__container {
    padding: 4rem 0 3rem;
  }
  .footer__list {
    column-gap: 3rem;
    margin: 3rem 0;
  }
  .footer__social {
    column-gap: 2rem;
  }

  .footer__social-link {
    font-size: 1.5rem;
  }
  .footer__copy {
    margin-top: 2rem;
  }
}

.slideshow {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  margin: 0 auto;
}

.slideshow img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(1.1) translateX(-1rem) rotate(-5deg);
  transition: all 0.5s ease-in-out;
  margin: 0 auto;
}

.slideshow .active {
  z-index: 1;
  opacity: 1;
  transform: scale(1) translateX(0) rotate(0);
}

.faq {
  direction: rtl;
  padding-block: 8rem 0;
}

.section__title {
  direction: rtl;
  font-size: 2rem;
  text-align: center;
}

.section__subtitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.faq__container {
  max-width: 800px;
  margin: 0 auto;
}

.faq__item {
  /* background-color: #2c2c2c; */
  border: 1px solid #858481;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
}

.faq__question {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
}

.faq__question:hover {
  /* background-color: #444444; */
}

.faq__answer {
  padding: 15px;
  display: none; /* Hide answers by default */
}

.faq__item.active .faq__answer {
  display: block; /* Show answer when active */
}

.offers {
  padding: 40px 20px;
  text-align: center;
}
.offers__title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #99484d;
}
.offers__container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between cards */
  flex-wrap: wrap; /* Wrap cards on smaller screens */
}
.offers__card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px; /* Fixed width for cards */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}
.dark-theme .offers__card {
  background-color: var(--container-color);

  box-shadow: 0 4px 10px rgba(161, 156, 156, 0.3);
}
.offers__image {
  width: 400px;
  height: 300px;
  border-radius: 5px;
}
.offers__price {
  font-size: 1.5rem;
  margin: 10px 0;
  color: var(--text-color);
}
.offers__description {
  margin: 10px 0;
  height: 70px;
  overflow: auto;
  color: var(--text-color);
  direction: rtl;
}
.offers__button {
  color: white;
  border: none;
  background-color: #99484d !important;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s;
}
.offers__button:hover {
  color: white;
}

ul {
  padding-left: 0rem;
}

.project-card {
  position: relative;
  width: 300px; /* عرض البطاقة */
  border-radius: 10px; /* زوايا مدورة */
  overflow: hidden; /* منع تجاوز المحتوى */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15); /* ظل للعمق */
  margin: 20px; /* فراغ حول البطاقة */
  background-color: #f8f8f8; /* خلفية فاتحة */
  color: #333; /* لون نص داكن */
  text-align: center; /* محاذاة النص للوسط */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* انتقالات سلسة */
}

.project-card__image-container {
  width: 100%; /* عرض كامل */
  height: 200px; /* ارتفاع ثابت للصورة */
  overflow: hidden; /* إخفاء الزائد */
}

.project-card__img {
  width: 100%; /* عرض كامل */
  height: 100%; /* ارتفاع كامل */
  object-fit: cover; /* الحفاظ على نسبة العرض إلى الارتفاع */
  transition: transform 0.5s ease; /* تأثير تكبير سلس */
}

.project-card__content {
  padding: 15px; /* فراغ داخلي */
}

.project-card__rank {
  background-color: #ff4757; /* لون الشارة */
  color: #fff; /* لون نص الشارة */
  padding: 5px 10px; /* حشوة حول الشارة */
  border-radius: 5px; /* زوايا مدورة للشارة */
  position: absolute; /* تحديد موقع الشارة في الأعلى */
  top: 10px;
  left: 10px;
  font-weight: bold; /* نص غامق */
}

.project-card__title {
  font-size: 20px; /* حجم عنوان البطاقة */
  margin: 10px 0; /* فراغ حول العنوان */
}

.project-card__description {
  font-size: 14px; /* حجم وصف البطاقة */
  color: #666; /* لون رمادي فاتح للوصف */
  margin: 10px 0; /* فراغ حول الوصف */
}

.project-card__price {
  display: block; /* عرض كتلة لسعر المنتج */
  font-size: 18px; /* حجم خط السعر */
  color: #007bff; /* لون أزرق للسعر */
  margin: 5px 0; /* فراغ حول السعر */
  font-weight: bold; /* نص غامق للسعر */
}

.project-card__genre {
  display: block; /* عرض كتلة لنوع المنتج */
  font-size: 12px; /* حجم خط النوع */
  color: #aaa; /* لون رمادي فاتح للنمط */
  margin: 5px 0; /* فراغ حول النوع */
}

/* أزرار العمل */
.project-card__actions {
  margin-top: 10px; /* فراغ فوق الأزرار */
}

.project-card__btn {
  background-color: #007bff; /* لون خلفية الزر */
  color: #fff; /* لون نص الزر */
  border: none; /* بدون حد */
  border-radius: 5px; /* زوايا مدورة */
  padding: 10px 15px; /* حشوة للزر */
  margin: 5px; /* فراغ بين الأزرار */
  cursor: pointer; /* مؤشر اليد */
  transition: background-color 0.3s ease; /* انتقال سلس للخلفية */
}

.project-card__btn:hover {
  background-color: #0056b3; /* لون أغمق عند المرور */
}

/* تأثيرات الهوفر */
.project-card:hover {
  transform: translateY(-5px); /* رفع البطاقة قليلاً */
  box-shadow: 0 25px 60px rgba(0, 0, 0, 0.25); /* ظل أقوى عند المرور */
}

.project-card:hover .project-card__img {
  transform: scale(1.05); /* تكبير الصورة عند المرور */
}

a {
  text-decoration: none;
}

.react-multi-carousel-dot-list {
  margin-top: 150px !important;
}

.dark-theme .nav img,
.dark-theme .nav ul,
.dark-theme .nav li,
.dark-theme .nav a,
.dark-theme .nav i,
.dark-theme .nav .nav__buttons {
  background-color: transparent;
}
.react-multiple-carousel__arrow {
  min-width: 0 !important;
  background-color: transparent !important;
}

.react-multiple-carousel__arrow::before {
  color: black !important;
  font-size: 20px !important;
}
.nav img {
  background-color: transparent;
}
a {
  text-decoration: none !important;
}
ul {
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}
/* src/components/Testimonial.css */
.testimonial__image {
  width: 100%;
  height: auto;
  max-width: 400px;
}

@media (max-width: 768px) {
  .testimonial__image {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .testimonial__image {
    max-width: 200px;
  }
}
